exports.components = {
  "component---src-pages-10-things-to-consider-before-renewing-oracle-license-index-tsx": () => import("./../../../src/pages/10-things-to-consider-before-renewing-oracle-license/index.tsx" /* webpackChunkName: "component---src-pages-10-things-to-consider-before-renewing-oracle-license-index-tsx" */),
  "component---src-pages-20-questions-to-ask-your-potential-open-jdk-vendor-index-tsx": () => import("./../../../src/pages/20-questions-to-ask-your-potential-open-jdk-vendor/index.tsx" /* webpackChunkName: "component---src-pages-20-questions-to-ask-your-potential-open-jdk-vendor-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-7-reasons-to-choose-liberica-jdk-index-tsx": () => import("./../../../src/pages/7-reasons-to-choose-liberica-jdk/index.tsx" /* webpackChunkName: "component---src-pages-7-reasons-to-choose-liberica-jdk-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-alpaquita-containers-for-spring-boot-apps-index-tsx": () => import("./../../../src/pages/alpaquita-containers-for-spring-boot-apps/index.tsx" /* webpackChunkName: "component---src-pages-alpaquita-containers-for-spring-boot-apps-index-tsx" */),
  "component---src-pages-alpaquita-containers-index-tsx": () => import("./../../../src/pages/alpaquita-containers/index.tsx" /* webpackChunkName: "component---src-pages-alpaquita-containers-index-tsx" */),
  "component---src-pages-alpaquita-linux-index-tsx": () => import("./../../../src/pages/alpaquita-linux/index.tsx" /* webpackChunkName: "component---src-pages-alpaquita-linux-index-tsx" */),
  "component---src-pages-alpaquita-support-index-tsx": () => import("./../../../src/pages/alpaquita-support/index.tsx" /* webpackChunkName: "component---src-pages-alpaquita-support-index-tsx" */),
  "component---src-pages-choose-an-optimal-linux-for-the-cloud-index-tsx": () => import("./../../../src/pages/choose-an-optimal-linux-for-the-cloud/index.tsx" /* webpackChunkName: "component---src-pages-choose-an-optimal-linux-for-the-cloud-index-tsx" */),
  "component---src-pages-cloud-native-platform-index-tsx": () => import("./../../../src/pages/cloud-native-platform/index.tsx" /* webpackChunkName: "component---src-pages-cloud-native-platform-index-tsx" */),
  "component---src-pages-comparison-of-popular-linux-distributions-for-the-cloud-index-tsx": () => import("./../../../src/pages/comparison-of-popular-linux-distributions-for-the-cloud/index.tsx" /* webpackChunkName: "component---src-pages-comparison-of-popular-linux-distributions-for-the-cloud-index-tsx" */),
  "component---src-pages-devoxx-survey-index-tsx": () => import("./../../../src/pages/devoxx-survey/index.tsx" /* webpackChunkName: "component---src-pages-devoxx-survey-index-tsx" */),
  "component---src-pages-effortless-java-upgrade-made-possible-index-tsx": () => import("./../../../src/pages/effortless-java-upgrade-made-possible/index.tsx" /* webpackChunkName: "component---src-pages-effortless-java-upgrade-made-possible-index-tsx" */),
  "component---src-pages-iana-updater-index-tsx": () => import("./../../../src/pages/iana-updater/index.tsx" /* webpackChunkName: "component---src-pages-iana-updater-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-java-cloud-deployment-cost-optimization-index-tsx": () => import("./../../../src/pages/java-cloud-deployment-cost-optimization/index.tsx" /* webpackChunkName: "component---src-pages-java-cloud-deployment-cost-optimization-index-tsx" */),
  "component---src-pages-kubecon-index-tsx": () => import("./../../../src/pages/kubecon/index.tsx" /* webpackChunkName: "component---src-pages-kubecon-index-tsx" */),
  "component---src-pages-liberica-administration-center-index-tsx": () => import("./../../../src/pages/liberica-administration-center/index.tsx" /* webpackChunkName: "component---src-pages-liberica-administration-center-index-tsx" */),
  "component---src-pages-liberica-mission-control-index-tsx": () => import("./../../../src/pages/liberica-mission-control/index.tsx" /* webpackChunkName: "component---src-pages-liberica-mission-control-index-tsx" */),
  "component---src-pages-liberica-native-image-kit-index-tsx": () => import("./../../../src/pages/liberica-native-image-kit/index.tsx" /* webpackChunkName: "component---src-pages-liberica-native-image-kit-index-tsx" */),
  "component---src-pages-libericajdk-containers-index-tsx": () => import("./../../../src/pages/libericajdk-containers/index.tsx" /* webpackChunkName: "component---src-pages-libericajdk-containers-index-tsx" */),
  "component---src-pages-libericajdk-for-embedded-index-tsx": () => import("./../../../src/pages/libericajdk-for-embedded/index.tsx" /* webpackChunkName: "component---src-pages-libericajdk-for-embedded-index-tsx" */),
  "component---src-pages-libericajdk-index-tsx": () => import("./../../../src/pages/libericajdk/index.tsx" /* webpackChunkName: "component---src-pages-libericajdk-index-tsx" */),
  "component---src-pages-libericajdk-performance-edition-index-tsx": () => import("./../../../src/pages/libericajdk-performance-edition/index.tsx" /* webpackChunkName: "component---src-pages-libericajdk-performance-edition-index-tsx" */),
  "component---src-pages-libericajdk-with-crac-index-tsx": () => import("./../../../src/pages/libericajdk-with-crac/index.tsx" /* webpackChunkName: "component---src-pages-libericajdk-with-crac-index-tsx" */),
  "component---src-pages-media-kit-index-tsx": () => import("./../../../src/pages/media-kit/index.tsx" /* webpackChunkName: "component---src-pages-media-kit-index-tsx" */),
  "component---src-pages-nik-support-index-tsx": () => import("./../../../src/pages/nik-support/index.tsx" /* webpackChunkName: "component---src-pages-nik-support-index-tsx" */),
  "component---src-pages-oracle-java-vs-open-jdk-distributions-index-tsx": () => import("./../../../src/pages/oracle-java-vs-open-jdk-distributions/index.tsx" /* webpackChunkName: "component---src-pages-oracle-java-vs-open-jdk-distributions-index-tsx" */),
  "component---src-pages-pages-6-7-java-support-index-tsx": () => import("./../../../src/pages/pages/6-7-java-support/index.tsx" /* webpackChunkName: "component---src-pages-pages-6-7-java-support-index-tsx" */),
  "component---src-pages-pages-downloads-alpaquita-cloud-image-index-tsx": () => import("./../../../src/pages/pages/downloads/alpaquita-cloud-image/index.tsx" /* webpackChunkName: "component---src-pages-pages-downloads-alpaquita-cloud-image-index-tsx" */),
  "component---src-pages-pages-downloads-alpaquita-index-tsx": () => import("./../../../src/pages/pages/downloads/alpaquita/index.tsx" /* webpackChunkName: "component---src-pages-pages-downloads-alpaquita-index-tsx" */),
  "component---src-pages-pages-downloads-index-tsx": () => import("./../../../src/pages/pages/downloads/index.tsx" /* webpackChunkName: "component---src-pages-pages-downloads-index-tsx" */),
  "component---src-pages-pages-downloads-native-image-kit-index-tsx": () => import("./../../../src/pages/pages/downloads/native-image-kit/index.tsx" /* webpackChunkName: "component---src-pages-pages-downloads-native-image-kit-index-tsx" */),
  "component---src-pages-pages-nik-supported-configurations-index-tsx": () => import("./../../../src/pages/pages/nik-supported-configurations/index.tsx" /* webpackChunkName: "component---src-pages-pages-nik-supported-configurations-index-tsx" */),
  "component---src-pages-pages-support-login-index-tsx": () => import("./../../../src/pages/pages/support-login/index.tsx" /* webpackChunkName: "component---src-pages-pages-support-login-index-tsx" */),
  "component---src-pages-pages-supported-configurations-index-tsx": () => import("./../../../src/pages/pages/supported-configurations/index.tsx" /* webpackChunkName: "component---src-pages-pages-supported-configurations-index-tsx" */),
  "component---src-pages-security-of-java-applications-index-tsx": () => import("./../../../src/pages/security-of-java-applications/index.tsx" /* webpackChunkName: "component---src-pages-security-of-java-applications-index-tsx" */),
  "component---src-pages-unified-java-runtime-index-tsx": () => import("./../../../src/pages/unified-java-runtime/index.tsx" /* webpackChunkName: "component---src-pages-unified-java-runtime-index-tsx" */),
  "component---src-pages-vulnerability-report-index-tsx": () => import("./../../../src/pages/vulnerability-report/index.tsx" /* webpackChunkName: "component---src-pages-vulnerability-report-index-tsx" */),
  "component---src-pages-webinars-mastering-kubernetes-best-practices-index-tsx": () => import("./../../../src/pages/webinars/mastering-kubernetes-best-practices/index.tsx" /* webpackChunkName: "component---src-pages-webinars-mastering-kubernetes-best-practices-index-tsx" */),
  "component---src-pages-zh-cn-webinars-mastering-kubernetes-best-practices-index-tsx": () => import("./../../../src/pages/zh-cn/webinars/mastering-kubernetes-best-practices/index.tsx" /* webpackChunkName: "component---src-pages-zh-cn-webinars-mastering-kubernetes-best-practices-index-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-content-library-tsx": () => import("./../../../src/templates/content-library.tsx" /* webpackChunkName: "component---src-templates-content-library-tsx" */),
  "component---src-templates-doc-page-js": () => import("./../../../src/templates/docPage.js" /* webpackChunkName: "component---src-templates-doc-page-js" */),
  "component---src-templates-newsroom-tsx": () => import("./../../../src/templates/newsroom.tsx" /* webpackChunkName: "component---src-templates-newsroom-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-subscribe-tsx": () => import("./../../../src/templates/subscribe.tsx" /* webpackChunkName: "component---src-templates-subscribe-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */)
}

