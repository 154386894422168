import 'styles/common.scss';
import '@bellsoft-ui-react/core/dist/cjs/index.css';
import '@bellsoft-ui-react/core/dist/esm/index.css';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import {generateCopyButtons} from "./gatsby-browser-modules/codeCopy";
import {generateSimplebars} from "./gatsby-browser-modules/scrollbars";
import {reportRouteChange} from "./gatsby-browser-modules/gaEnhancements";
import {
    tryJdkPerfModalAfterDownloadMethod,
    wrongDomainModalMethod
} from "./src/util/modals/modalTemplates";

const initDocSearch = () => {
    if (window && document) {
        try {
            const docSearchStub = document.getElementById("docsearch-stub-btn")
            if (docSearchStub) {
                docSearchStub.style.display = "none"
            }
        } catch (e) {
        }
        if (!(document.getElementById("docsearch")?.innerHTML)) {
            try {
                const {docsearch} = window.__docsearch_meilisearch__;
                docsearch({
                    container: "#docsearch",
                    host: process.env.GATSBY_MEILISEARCH_HOST,
                    apiKey: process.env.GATSBY_MEILISEARCH_PUBLIC_TOKEN,
                    indexUid: "globalsearch",
                });
            } catch (e) {
            }
        }
    }
}

export const onInitialClientRender = () => {
    console.log("ReactDOM.render has executed");

    if (window && document) {

        const script = document.createElement("script")
        script.setAttribute("src", "/assets/js/bootstrap.bundle.min.js");
        script.type = "text/javascript";

        script.onload = function() {
            if (!window["bsClickedModals"]) {
                window["bsClickedModals"] = new Set()
            }

            for (const clickedModal of window["bsClickedModals"]) {
                try {
                    const myModalEl = document.querySelector(clickedModal)
                    window["bsClickedModals"].delete(clickedModal);
                    const modal = window.bootstrap.Modal.getOrCreateInstance(myModalEl)
                    modal.show()
                } catch (e) {

                }
            }
        };

        document.head.appendChild(script)
    }
}

export const onRouteUpdate = ({location, prevLocation}) => {
    initDocSearch();
    generateCopyButtons();
    generateSimplebars();
    reportRouteChange({location, prevLocation});

    tryJdkPerfModalAfterDownloadMethod();
    wrongDomainModalMethod();


    if (window && document && prevLocation) {
        if (!window["bsClickedModals"]) {
            window["bsClickedModals"] = new Set()
        }

        window["bsClickedModals"].clear()
    }
}

export const onPreRouteUpdate = () => {
}
